import {AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {VideoService} from '../services/video.service';

import {Capacitor, Plugins} from '@capacitor/core';
import * as WebVPPlugin from 'capacitor-video-player';
import { TranslateService } from '@ngx-translate/core';

const {CapacitorVideoPlayer} = Plugins;

@Component({
  selector: 'app-enmarcar',
  templateUrl: 'enmarcar.page.html',
  styleUrls: ['enmarcar.page.scss']
})
export class EnmarcarPage implements OnInit, AfterContentInit  {
  displayName = 'test';
  shouldDisable = true;
  private timer: any; // Almacena la referencia al temporizador

  constructor(    private translate: TranslateService,private router: Router) {
  }

  ngAfterContentInit() {
    setTimeout(x => {
      this.shouldDisable = false;
    }, 5000);//5 seconds
  }


  ngOnInit() {

    this.startTimer();

    localStorage.setItem('view_set_finish', '0');
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user)
    console.log(user)
    console.log(user.language)
    this.translate.use(user.language);
    if (user) {
      this.displayName = user.first_name + ' ' + user.last_name;
      if (user.data_protection === 1) {
        this.router.navigate(['/enmarcar']);
      }
    } else {
      this.router.navigate(['/']);
      localStorage.clear();
    }

  }

  ngOnDestroy() {

    // Asegurarse de limpiar el temporizador cuando el componente se destruya
    clearTimeout(this.timer);
  }

  startTimer() {

    this.timer = setTimeout(() => {

        this.router.navigate(['/instrucciones']);
    }, 10000); // 10 segundos
  }

  onContinue() {

    // Limpiar el temporizador para prevenir la redirección automática
    clearTimeout(this.timer);
    // Navegar manualmente
    this.router.navigate(['/instrucciones']);
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/']);
  }
}

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private translate: TranslateService, private router: Router) {
    this.initializeApp();
  }

  initializeApp() {
    // Verificar si hay un idioma almacenado y aplicarlo
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      this.translate.use(savedLanguage);
      console.log('Idioma cargado: ' + savedLanguage);
    } else {
      // Establecer un idioma predeterminado si no hay uno guardado
      this.translate.setDefaultLang('es');
      console.log('Idioma predeterminado: es');
    }

    // Verificar si hay un token almacenado y redirigir
    const token = localStorage.getItem('token');
    if (token) {
      this.router.navigate(['/enmarcar']); // Cambia '/enmarcar' a la URL que necesites
    }
  }
}

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './services/login.service';
import { Platform } from '@ionic/angular';
import { PreloadService } from './utils/preload.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private loginService: LoginService,
    private translate: TranslateService,
    private platform: Platform,
    private preloadService: PreloadService
  ) {
    this.initializeApp();
  }
  
  private async initializeApp() {
    try {
      // Esperar a que la plataforma esté lista
      await this.platform.ready();
      
      // Ejecutar la precarga para evitar pantallas en blanco
      await this.preloadService.preload();
      
      // Inicializar sistema de traducción
      await this.initTranslate();
      
      // Configurar manejo de errores para prevenir pantallas en blanco
      this.setupErrorHandling();
      
      // Registro de estado de inicialización exitoso
      console.log('Aplicación inicializada correctamente');
    } catch (error) {
      console.error('Error initializing app:', error);
      // Intentar reinicializar componentes críticos si hay error
      this.recoverFromError();
    }
  }
  
  /**
   * Configurar manejo de errores global para evitar pantallas en blanco
   */
  private setupErrorHandling() {
    window.addEventListener('error', (event) => {
      console.error('Error no capturado:', event.error);
      // Evitar que los errores no manejados causen pantalla en blanco
      event.preventDefault();
      return true;
    });
    
    window.addEventListener('unhandledrejection', (event) => {
      console.error('Promesa rechazada no manejada:', event.reason);
      // Evitar que las promesas rechazadas no manejadas causen pantalla en blanco
      event.preventDefault();
    });
  }
  
  /**
   * Intenta recuperarse de errores graves que podrían causar pantallas en blanco
   */
  private recoverFromError() {
    try {
      // Forzar la reinicialización de componentes críticos
      this.initTranslate().catch(err => console.error('Error en recuperación de traducción:', err));
      
      // Notificar al usuario que hubo un problema (opcional)
      console.log('Recuperación de error iniciada');
    } catch (secondError) {
      console.error('Error en recuperación de error:', secondError);
    }
  }

  private async initTranslate() {
    // Establecer español como idioma por defecto
    this.translate.setDefaultLang('es');
    
    // Asegurarse de que las traducciones se carguen
    try {
      await this.translate.use('es').toPromise();
    } catch (error) {
      console.error('Error loading translations:', error);
    }
  }

  ngOnInit() {
    // Asegurarse de que las traducciones estén disponibles
    if (!this.translate.currentLang) {
      this.translate.use('es');
    }
  }
}

import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EnmarcarPage } from './enmarcar.page';

import { EnmarcarPageRoutingModule } from './enmarcar-routing.module';
import { CameraVideoModule } from '../camera-video/camera-video.module';
import { ModalFigurasModule } from '../modal-figuras/modal-figuras.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    EnmarcarPageRoutingModule,
    CameraVideoModule,
    ModalFigurasModule,
    TranslateModule
  ],
  declarations: [EnmarcarPage],
  exports:[
    EnmarcarPage
  ]
})

export class EnmarcarPageModule {}

import { Injectable } from '@angular/core';
import {
  Plugins
} from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GamesService {
  public videos = [];
  private VIDEOS_KEY: string = 'videos';

  constructor(private httpClient: HttpClient) {}

  getParameters(word_game_id: string): Observable<Record<string, any>> {
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/get-parameters`,{word_game_id: word_game_id}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }

  setInfoGame1(password: string, figure: string, color: string, user_id: number): Observable<Record<string, any>> {
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/set-memorize`, {password: password,figure: figure, color:color, user_id: user_id}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }

  setGameHeader(user_id: number, level: number, sequence: string, game_stay: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/set-game-header`, {user_id: user_id,level: level, sequence:sequence, game_stay: game_stay}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }

  mentalSpeedDetails(mental_speed_id: number, value_expected: number, value_pressed: number, time: number, time_pulsation: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/mental-speed-details`, {mental_speed_id: mental_speed_id,value_expected: value_expected, value_pressed:value_pressed, time: time, time_pulsation: time_pulsation}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }

  mentalSpeedStop(mental_speed_id: number,time: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/mental-speed-stop`, {mental_speed_id: mental_speed_id, time: time}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }

  mentalCalculation(game_header_id: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/mental-calculation`, {game_header_id: game_header_id}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }

  mentalCalculationDetails(mental_calculation_id: number, value_expected: number, value_entered: number, time: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/mental-calculation-details`, {mental_calculation_id: mental_calculation_id, value_expected: value_expected, value_entered:value_entered, time:time}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }

  mentalCalculationStop(mental_calculation_id: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    let time = Date.now();
    return this.httpClient
        .post(`${environment.apiUrl}/mental-calculation-stop`, {mental_calculation_id: mental_calculation_id, time: time}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }

  gameWordsInit(game_header_id: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/words-game-start`, {game_header_id: game_header_id}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }

  getGameWords(game_header_id: number, letter: string, level_time: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/words-game`, {game_header_id: game_header_id, letter: letter, level_time: level_time}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }
  gameWordsDetails(word_game_id: number, word: string, pulsation: number, time_pulsation: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/words-game-details`, {word_game_id: word_game_id, word: word, pulsation: pulsation, time_pulsation:time_pulsation}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }
  gameWordsStop(time_total,word_game_id: number, words_array: any,time: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/words-game-stop`, {time_total:time_total,word_game_id: word_game_id, words_array: words_array, time: time}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }
  memoricePasswordDetails(game_header_id: number, memorize_id: number, value_expected: string, value_entered: string, time: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
        .post(`${environment.apiUrl}/memorize-password-details`, {game_header_id: game_header_id,memorize_id: memorize_id, value_expected: value_expected, value_entered: value_entered, time:time}, httpOptions)
        .pipe((result) => {
            return result;
        });
  }
  memoriceFigureStop(game_header_id: number, memorize_figure_id: number, memorize_id: number, figures_array: any){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
      .post(`${environment.apiUrl}/memorize-figures-details`, {game_header_id: game_header_id,memorize_id: memorize_id, memorize_figure_id: memorize_figure_id, figures_array: figures_array}, httpOptions)
      .pipe((result) => {
        return result;
      });
  }
  finalizeTest(game_header_id: number){
    const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    return this.httpClient
      .post(`${environment.apiUrl}/videos/process`, {game_header_id: game_header_id}, httpOptions)
      .pipe((result) => {
        return result;
      });
  }
}

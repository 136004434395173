import { Injectable } from '@angular/core';
import {
  Plugins
} from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

interface LoginResponse {
  success: boolean;
  token: string;
  user: {
    id: number;
    language: string;
    [key: string]: any;
  };
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public videos = [];
  private VIDEOS_KEY: string = 'videos';
 
  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService
  ) {}
 
  getToken(): Observable<LoginResponse> {
    return this.httpClient
        .post<LoginResponse>(`${environment.apiUrl}/login_check`, {username: "asotillo",password: "12345678"})
        .pipe(
          tap(result => {
            console.log('=== Llamada a login_check ===');
            console.log('URL:', `${environment.apiUrl}/login_check`);
            console.log('Response:', result);
          })
        );
  }
  loginCode(code: string): Observable<LoginResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    console.log('=== Llamada a auth/login ===');
    console.log('URL:', `${environment.apiUrl}/auth/login`);
    console.log('Payload:', { code });
    
    return this.httpClient
        .post<LoginResponse>(`${environment.apiUrl}/auth/login`, {code: code}, httpOptions)
        .pipe(
          tap(result => {
            console.log('Response:', result);
            if (result.success && result.user?.language) {
              const userLanguage = result.user.language;
              if (userLanguage.match(/^(es|en)$/)) {
                this.translate.use(userLanguage);
                localStorage.setItem('user_language', userLanguage);
              } else {
                this.translate.use('es');
                localStorage.setItem('user_language', 'es');
              }
            }
          })
        );
  }
  data_protection(user_id: number, token: string): Observable<Record<string, any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: "Bearer " + token
      })
    };
    console.log('=== Llamada a set-data-protection ===');
    console.log('URL:', `${environment.apiUrl}/set-data-protection`);
    console.log('Payload:', { user_id });
    
    return this.httpClient
        .post(`${environment.apiUrl}/set-data-protection`, {user_id: user_id}, httpOptions)
        .pipe(
          tap(result => {
            console.log('Response:', result);
          })
        );
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-instrucciones-calculo-mental',
  templateUrl: './instrucciones-calculo-mental.page.html',
  styleUrls: ['./instrucciones-calculo-mental.page.scss'],
})
export class InstruccionesCalculoMentalPage implements OnInit {
  private timer: any; // Almacena la referencia al temporizador

  constructor(
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.startTimer();
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      // Establecer el idioma según la configuración del usuario
      if (user.language === 'en') {
        this.translate.use('en');
      } else {
        this.translate.use('es');
      }
    } else {
      this.router.navigate(['/']);
      localStorage.clear();
    }
  }

  ngOnDestroy() {
    this.clearTimer();
  }

  private clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  startTimer() {
    this.clearTimer();
    this.timer = setTimeout(() => {
      this.router.navigate(['/calculo-mental']);
    }, 10000); // 10 segundos
  }

  onContinue() {
    this.clearTimer();
    this.router.navigate(['/calculo-mental']);
  }
}

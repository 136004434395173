import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

import { GamesService } from '../services/games.service';
import { VideoService } from '../services/video.service';

@Component({
  selector: 'app-memoriza-simbolo',
  templateUrl: './memoriza-simbolo.page.html',
  styleUrls: ['./memoriza-simbolo.page.scss'],
})
export class MemorizaSimboloPage implements OnInit, OnDestroy {
  figure: any;
  color: any;
  figure_translate: any;
  color_translate: any;
  password: any;
  user_id: any;
  text1: string;
  private timer: any;

  constructor(
    private router: Router,
    private gamesService: GamesService,
    private videoService: VideoService,
    public toastController: ToastController
  ) { }

  ngOnInit() {
    this.startTimer();
    let user = JSON.parse(localStorage.getItem('user'));
    if(user){
      this.figure = localStorage.getItem('figure_game');
      this.color = localStorage.getItem("color_figure_game");
      this.password = localStorage.getItem('password_game');
      this.user_id = user.id;

      // Lógica de traducción según el idioma del usuario
      if (user.language === 'es') {
        // Traducción de figuras al español
        if(this.figure == 'circle'){
          this.figure_translate = 'círculos';
        }
        if(this.figure == 'triangle'){
          this.figure_translate = 'triángulos';
        }
        if(this.figure == 'square'){
          this.figure_translate = 'cuadrados';
        }
        if(this.figure == 'cross'){
          this.text1 = "todas las";
          this.figure_translate = 'cruces';
        }

        // Traducción de colores al español
        if(this.color == 'blue'){
          this.color_translate = 'azules';
        }
        if(this.color == 'red'){
          if(this.figure == 'cross'){
            this.color_translate = 'rojas';
          } else {
            this.color_translate = 'rojos';
          }
        }
        if(this.color == 'green'){
          this.color_translate = 'verdes';
        }
        if(this.color == 'yellow'){
          if(this.figure == 'cross'){
            this.color_translate = 'amarillas';
          } else {
            this.color_translate = 'amarillos';
          }
        }
        if(!this.text1) {
          this.text1 = "solo";
        }
      } else {
        // Traducción de figuras al inglés
        if(this.figure == 'circle'){
          this.figure_translate = 'circles';
        }
        if(this.figure == 'triangle'){
          this.figure_translate = 'triangles';
        }
        if(this.figure == 'square'){
          this.figure_translate = 'squares';
        }
        if(this.figure == 'cross'){
          this.text1 = "all";
          this.figure_translate = 'crosses';
        }

        // Traducción de colores al inglés
        if(this.color == 'blue'){
          this.color_translate = 'blue';
        }
        if(this.color == 'red'){
          this.color_translate = 'red';
        }
        if(this.color == 'green'){
          this.color_translate = 'green';
        }
        if(this.color == 'yellow'){
          this.color_translate = 'yellow';
        }
        if(!this.text1) {
          this.text1 = "only";
        }
      }
    } else {
      this.router.navigate(['/']);
      localStorage.clear();
    }
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'bottom',
      color: "danger"
    });
    toast.present();
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  startTimer() {
    this.timer = setTimeout(() => {
      this.router.navigate(['/instrucciones-rapidez-mental']);
    }, 10000);
  }

  onContinue() {
    clearTimeout(this.timer);
    this.router.navigate(['/instrucciones-rapidez-mental']);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { GamesService } from '../services/games.service';
import { VideoService } from '../services/video.service';

@Component({
  selector: 'app-memoriza-simbolo',
  templateUrl: './memoriza-simbolo.page.html',
  styleUrls: ['./memoriza-simbolo.page.scss'],
})
export class MemorizaSimboloPage implements OnInit, OnDestroy {

  figure: any;
  color: any;
  figure_translate: any;
  color_translate: any;
  password: any;
  user_id: any;
  text1: string;
  private timer: any; // Almacena la referencia al temporizador

  constructor(
    private router: Router,
    private gamesService: GamesService,
    private videoService: VideoService,
    public toastController: ToastController,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.startTimer();
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.figure = localStorage.getItem('figure_game');
      this.color = localStorage.getItem('color_figure_game');
      this.password = localStorage.getItem('password_game');
      this.user_id = user.id;

      this.translateFigure();
      this.translateColor();
    } else {
      this.router.navigate(['/']);
      localStorage.clear();
    }
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'bottom',
      color: "danger"
    });
    toast.present();
  }

  ngOnDestroy() {
    // Asegurarse de limpiar el temporizador cuando el componente se destruya
    clearTimeout(this.timer);
  }

  startTimer() {
    this.timer = setTimeout(() => {
      // Redirige solo si el componente aún está montado y el usuario no ha navegado manualmente
      this.router.navigate(['/instrucciones-rapidez-mental']);
    }, 10000); // 10 segundos
  }

  onContinue() {
    clearTimeout(this.timer);
    this.router.navigate(['/instrucciones-rapidez-mental']);
  }

  private translateFigure() {
    switch (this.figure) {
      case 'circle':
        this.figure_translate = this.translate.instant('MEMORIZE_SYMBOL.CIRCLES');
        this.text1 = this.translate.instant('MEMORIZE_SYMBOL.ALL');
        break;
      case 'triangle':
        this.figure_translate = this.translate.instant('MEMORIZE_SYMBOL.TRIANGLES');
        this.text1 = this.translate.instant('MEMORIZE_SYMBOL.ALL');
        break;
      case 'square':
        this.figure_translate = this.translate.instant('MEMORIZE_SYMBOL.SQUARES');
        this.text1 = this.translate.instant('MEMORIZE_SYMBOL.ALL');
        break;
      case 'cross':
        this.figure_translate = this.translate.instant('MEMORIZE_SYMBOL.CROSSES');
        this.text1 = this.translate.instant('MEMORIZE_SYMBOL.ALL').replace('todos', 'todas');
        break;
    }
  }

  private translateColor() {
    switch (this.color) {
      case 'lilac':
        this.color_translate = this.translate.instant('MEMORIZE_SYMBOL.LILAC');
        break;
      case 'blue':
        this.color_translate = this.translate.instant('MEMORIZE_SYMBOL.BLUE');
        break;
      case 'red':
        this.color_translate = this.translate.instant(`MEMORIZE_SYMBOL.RED.${this.figure === 'cross' ? 'FEMALE' : 'MALE'}`);
        break;
      case 'white':
        this.color_translate = this.translate.instant(`MEMORIZE_SYMBOL.WHITE.${this.figure === 'cross' ? 'FEMALE' : 'MALE'}`);
        break;
    }
  }
}

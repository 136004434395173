import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { 
  AuthGuardService as AuthGuard 
} from './auth/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'politica-datos',
    loadChildren: () => import('./politica-datos/politica-datos.module').then( m => m.PoliticaDatosPageModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'instrucciones',
    loadChildren: () => import('./instrucciones/instrucciones.module').then( m => m.InstruccionesPageModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'enmarcar',
    loadChildren: () => import('./enmarcar/enmarcar.module').then( m => m.EnmarcarPageModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'memoriza-contrasena',
    loadChildren: () => import('./memoriza-contrasena/memoriza-contrasena.module').then( m => m.MemorizaContrasenaPageModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'memoriza-simbolo',
    loadChildren: () => import('./memoriza-simbolo/memoriza-simbolo.module').then( m => m.MemorizaSimboloPageModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'instrucciones-rapidez-mental',
    loadChildren: () => import('./instrucciones-rapidez-mental/instrucciones-rapidez-mental.module').then( m => m.InstruccionesRapidezMentalPageModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'rapidez-mental',
    loadChildren: () => import('./rapidez-mental/rapidez-mental.module').then( m => m.RapidezMentalPageModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'instrucciones-calculo-mental',
    loadChildren: () => import('./instrucciones-calculo-mental/instrucciones-calculo-mental.module').then( m => m.InstruccionesCalculoMentalPageModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'calculo-mental',
    loadChildren: () => import('./calculo-mental/calculo-mental.module').then( m => m.CalculoMentalPageModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'instrucciones-juego-palabras',
    loadChildren: () => import('./instrucciones-juego-palabras/instrucciones-juego-palabras.module').then( m => m.InstruccionesJuegoPalabrasPageModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'juego-palabras',
    loadChildren: () => import('./juego-palabras/juego-palabras.module').then( m => m.JuegoPalabrasPageModule)
  },
  {
    path: 'ingresa-contrasena',
    loadChildren: () => import('./ingresa-contrasena/ingresa-contrasena.module').then( m => m.IngresaContrasenaPageModule)
  },
  {
    path: 'exit',
    loadChildren: () => import('./exit/exit.module').then( m => m.ExitPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
@Injectable()
export class AuthService {
  constructor() {}
  // ...
  public isAuthenticated(): boolean {
    const user = localStorage.hasOwnProperty('user');
    // Check whether the token is expired and return
    // true or false
    return user;
  }
}
import {Component, Input, OnInit} from '@angular/core';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import {environment} from 'src/environments/environment';
import {GamesService} from '../services/games.service';

@Component({
    selector: 'app-modal-figuras',
    templateUrl: './modal-figuras.component.html',
    styleUrls: ['./modal-figuras.component.scss'],
})
export class ModalFigurasComponent implements OnInit {
    figuresArray: any;
    figuresArray2: any;
    numerosArray: any;
    numerosArray2: any;
    totalFiguresArray: any;
    indexfiguresArray = 0;
    position: any;
    intervalFigures: any;
    stopFiguresArray = false;
    numberIntervalArray: any;
    figures_clicks = [];
    clickingGame = -1;
    numberFigures: any;
    figures_interval: any;
    total_figures_array: any;
    hasClick = false;
    stopCounting = false;
    indexEach = -1;
    range_time_game = 0;
    times_level: any;
    times_total_level: any;
    level: any;
    memorize_figure_id: any;
    game_header_id: any;
    memorize_id: any;
    last_time: any;
    game_stay_nav: '';
    @Input() public game_stay: string;

    constructor(private router: Router, private gamesService: GamesService) {
    }

    ngOnInit() {
        this.last_time = Date.now();
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                localStorage.setItem('figures_clicks', JSON.stringify(this.figures_clicks));
                localStorage.setItem('clicking_game_figure', this.clickingGame.toString());
                localStorage.setItem('has_click_figure', this.hasClick.toString());
                if (event.url === '/instrucciones-rapidez-mental' || event.url === '/rapidez-mental') {
                    this.game_stay = '1';
                }
                if (event.url === '/instrucciones-calculo-mental' || event.url === '/calculo-mental') {
                    this.game_stay = '2';
                }
                if (event.url === '/instrucciones-juego-palabras' || event.url === '/juego-palabras') {
                    this.game_stay = '3';
                }
                if (event.url === '/ingresa-contrasena') {
                    this.game_stay = '4';
                }
                if (event.url === '/exit') {
                    this.game_stay = '5';
                }
                if (event.url === '/instrucciones-rapidez-mental') {
                    this.memorize_figure_id = localStorage.getItem('memorize_figure_id');
                    this.game_header_id = localStorage.getItem('game_header_id');
                    this.memorize_id = localStorage.getItem('memorize_id');
                    this.figuresArray = JSON.parse(localStorage.getItem('figures_colors_array'));
                    this.figuresArray2 = JSON.parse(localStorage.getItem('figures_colors_array'));
                    this.numberIntervalArray = parseInt(localStorage.getItem('number_interval_array'), 10);
                    if (localStorage.getItem('clicking_game_figure')) {
                        this.clickingGame = parseInt(localStorage.getItem('clicking_game_figure'), 10);
                    }
                    if (localStorage.getItem('figures_clicks')) {
                        this.figures_clicks = JSON.parse(localStorage.getItem('figures_clicks'));
                    }
                    this.indexEach = this.figures_clicks.length - 1;

                    const user = JSON.parse(localStorage.getItem('user'));
                    this.level = user.level;
                    if (this.level === 1) {
                        this.times_level = environment.interval_show_figure[0];
                    } else if (this.level === 2) {
                        this.times_level = environment.interval_show_figure[1];
                    } else if (this.level === 3) {
                        this.times_level = environment.interval_show_figure[2];
                    }
                    this.times_total_level = environment.range_time_game;
                    if (this.game_stay === '5') {
                        clearInterval(this.intervalFigures);
                        this.gamesService.memoriceFigureStop(this.game_header_id, this.memorize_figure_id, this.memorize_id, JSON.stringify(this.figures_clicks)).subscribe(
                            (res) => {
                                this.stopFiguresArray = false;
                            },
                            (err) => {
                                this.stopFiguresArray = false;
                            }
                        );
                    } else {
                        this.intervalFigures = setInterval(() => {
                            let timestamp_now = Date.now();
                            if (parseInt(localStorage.getItem('number_interval_array'), 10) < this.times_total_level) {
                                if (!this.hasClick) {
                                    this.stopFiguresArray = false;
                                    this.numberIntervalArray = this.numberIntervalArray + 1;
                                    localStorage.setItem('number_interval_array', this.numberIntervalArray.toString());
                                    this.totalFiguresArray = this.figuresArray2.length;
                                    for (let j = 0; j < this.totalFiguresArray; j++) {
                                        if (this.figuresArray2[j].time === this.numberIntervalArray) {
                                            this.indexEach = this.indexEach + 1;
                                            this.clickingGame = this.clickingGame + 1;
                                            let difference = timestamp_now - this.last_time;
                                            this.last_time = Date.now();
                                            this.figures_clicks[this.indexEach] = {
                                                figure: this.figuresArray2[this.clickingGame].figure,
                                                color: this.figuresArray2[this.clickingGame].color,
                                                pulsation: 0,
                                                time: difference,
                                                time_pulsation: timestamp_now,
                                                game_stay: this.game_stay
                                            };
                                            this.stopFiguresArray = true;
                                        }
                                    }
                                } else {
                                    this.hasClick = false;
                                }
                            } else {
                                this.stopFiguresArray = false;
                                clearInterval(this.intervalFigures);
                                this.gamesService.memoriceFigureStop(this.game_header_id, this.memorize_figure_id, this.memorize_id, JSON.stringify(this.figures_clicks)).subscribe(
                                    (res) => {
                                        // console.log(res);
                                    },
                                    (err) => {
                                        // console.log(err)
                                    }
                                );
                                localStorage.removeItem('figures_clicks');
                                localStorage.removeItem('clicking_game_figure');
                                localStorage.removeItem('has_click_figure');
                                this.figures_clicks = [];
                                this.clickingGame = 1;
                                this.hasClick = false;
                            }
                        }, 1000);
                    }
                }
            }
        });
    }

    selectOverlay() {
        let timestamp_now = Date.now();
        this.indexEach = this.indexEach + 1;
        this.figures_clicks[this.indexEach] = {
            figure: this.figuresArray2[this.clickingGame].figure,
            color: this.figuresArray2[this.clickingGame].color,
            pulsation: -1,
            time_pulsation: timestamp_now,
            time: 0,
            game_stay: this.game_stay
        }
        ;
    }

    selectFiguras() {
        if (parseInt(localStorage.getItem('number_interval_array'), 10) < this.times_total_level) {
            clearInterval(this.intervalFigures);
            let timestamp_now = Date.now();
            let difference = timestamp_now - this.last_time;
            this.last_time = Date.now();
            this.stopFiguresArray = false;
            this.figures_clicks[this.indexEach] = {
                figure: this.figuresArray2[this.clickingGame].figure,
                color: this.figuresArray2[this.clickingGame].color,
                pulsation: 1,
                time_pulsation: timestamp_now,
                time: difference,
                game_stay: this.game_stay
            };
            this.hasClick = true;
            this.numberIntervalArray = this.numberIntervalArray + 1;
            this.intervalFigures = setInterval(() => {
                let timestamp_now = Date.now();
                if (parseInt(localStorage.getItem('number_interval_array'), 10) < this.times_total_level) {
                    if (!this.hasClick) {
                        this.stopFiguresArray = false;
                        this.numberIntervalArray = this.numberIntervalArray + 1;
                        localStorage.setItem('number_interval_array', this.numberIntervalArray.toString());
                        this.totalFiguresArray = this.figuresArray2.length;
                        for (let j = 0; j < this.totalFiguresArray; j++) {
                            if (this.figuresArray2[j].time === this.numberIntervalArray) {
                                this.indexEach = this.indexEach + 1;
                                this.clickingGame = this.clickingGame + 1;
                                let difference = timestamp_now - this.last_time;
                                this.last_time = Date.now();
                                this.figures_clicks[this.indexEach] = {
                                    figure: this.figuresArray2[this.clickingGame].figure,
                                    color: this.figuresArray2[this.clickingGame].color,
                                    pulsation: 0,
                                    time_pulsation: timestamp_now,
                                    time: difference,
                                    game_stay: this.game_stay
                                };
                                this.stopFiguresArray = true;
                            }
                        }
                    } else {
                        this.hasClick = false;
                    }
                } else {
                    this.stopFiguresArray = false;
                    clearInterval(this.intervalFigures);
                    this.gamesService.memoriceFigureStop(this.game_header_id, this.memorize_figure_id, this.memorize_id, JSON.stringify(this.figures_clicks)).subscribe(
                        (res) => {
                            // console.log(res);
                        },
                        (err) => {
                            // console.log(err)
                        }
                    );
                    localStorage.removeItem('figures_clicks');
                    localStorage.removeItem('clicking_game_figure');
                    localStorage.removeItem('has_click_figure');
                    this.figures_clicks = [];
                    this.clickingGame = 1;
                    this.hasClick = false;
                }
            }, 1000);
        } else {
            localStorage.removeItem('figures_clicks');
            localStorage.removeItem('clicking_game_figure');
            localStorage.removeItem('has_click_figure');
            this.figures_clicks = [];
            this.clickingGame = 1;
            this.hasClick = false;
        }
    }


}

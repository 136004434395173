import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { InstruccionesPageRoutingModule } from './instrucciones-routing.module';
import { CameraVideoModule } from '../camera-video/camera-video.module';

import { InstruccionesPage } from './instrucciones.page';
import { ModalFigurasModule } from '../modal-figuras/modal-figuras.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    InstruccionesPageRoutingModule,
    CameraVideoModule,
    ModalFigurasModule,
    TranslateModule
  ],
  declarations: [
    InstruccionesPage,]
})
export class InstruccionesPageModule {}

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '../services/login.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-politica-datos',
  templateUrl: 'politica-datos.page.html',
  styleUrls: ['politica-datos.page.scss']
})
export class PoliticaDatosPage {
  terms = false;
  error = false;
  version: any;
  constructor(private router: Router, private loginSevice: LoginService) {}

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.version = environment.version
    if(user){
      if(user.data_protection == 1){
        this.router.navigate(['/enmarcar'])
      }
    }else{
      this.router.navigate(['/'])
          localStorage.clear();
    }
  }

  onSubmit() {
    let terms = this.terms;
    if(terms){
      let token = localStorage.getItem('token');
      let user = JSON.parse(localStorage.getItem('user'));
      this.loginSevice.data_protection(user.id, token).subscribe(
        (res) => {
          if(res.success){
            // console.log(res);
            localStorage.setItem('user', JSON.stringify(res.user));
            this.router.navigate(['/enmarcar'])
          }
        },
        (err) => {
          // console.error(err);
          if(err.status == 401){
            this.router.navigate(['/'])
          localStorage.clear();
          }
        }
      );
    }else{
      this.error = true
    }
  }
  
  detectChange(){
    if(this.terms){
      this.error = false
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { InstruccionesCalculoMentalPageRoutingModule } from './instrucciones-calculo-mental-routing.module';

import { InstruccionesCalculoMentalPage } from './instrucciones-calculo-mental.page';
import { CameraVideoModule } from '../camera-video/camera-video.module';
import { ModalFigurasModule } from '../modal-figuras/modal-figuras.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    InstruccionesCalculoMentalPageRoutingModule,
    CameraVideoModule,
    ModalFigurasModule,
    TranslateModule.forChild() 
  ],
  declarations: [InstruccionesCalculoMentalPage]
})
export class InstruccionesCalculoMentalPageModule {}

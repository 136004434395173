import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MemorizaContrasenaPageRoutingModule } from './memoriza-contrasena-routing.module';

import { MemorizaContrasenaPage } from './memoriza-contrasena.page';
import { CameraVideoModule } from '../camera-video/camera-video.module';
import { ModalFigurasModule } from '../modal-figuras/modal-figuras.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MemorizaContrasenaPageRoutingModule,
    CameraVideoModule,
    ModalFigurasModule,
    TranslateModule
  ],
  declarations: [MemorizaContrasenaPage]
})
export class MemorizaContrasenaPageModule {}
